














import { Component, Prop } from 'vue-property-decorator'
import Base from '@/application/models/Base'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'
import { Getter } from 'vuex-class'

@Component({})
export default class DeviceInfo extends Base {
  @Getter('patientVitalsSources', { namespace: 'vitalSources' })
  patientVitalsSources!: Array<PatientVitalsSources>

  constructor () {
    super('device-info')
  }
}
