

























































































import { Component } from 'vue-property-decorator'
import Vital from '@/application/models/Vital'
import DeviceInfo from '@/components/common/DeviceInfo.vue'
import RealNumber from '@/components/dashboard/custom/RealNumber.vue'
import SimpleGraph from '@/components/dashboard/custom/SimpleGraph.vue'
import LineChart from '@/components/dashboard/custom/LineChart.vue'
import Base from '@/application/models/Base'
import PrettyChart from '@/components/dashboard/custom/PrettyChart.vue'
import { Getter } from 'vuex-class'
import Patient from '@/application/models/Patient'
import AddPatient from '@/components/common/AddPatient.vue'
import PatientVitalsSources from '@/application/models/PatientVitalsSources'

@Component({
  components: { AddPatient, PrettyChart, LineChart, SimpleGraph, RealNumber, DeviceInfo }
})

export default class DashboardView extends Base {
  @Getter('patients', { namespace: 'patients' })
  patients!: Array<PatientVitalsSources>

  public selectedItem = 0

  constructor () {
    super('dashboard')
  }

  get useNewGraph () {
    return {} // this.$store.state.appSettings.useNewGraph
  }

  get heartbeat () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.pulseRate)
  }

  get oxygenSaturation () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.oxygenSaturation)
  }

  get bodyTemperature () {
    return {} // this.$store.state.vitals.map((v: Vital) => v.bodyTemperature)
  }

  get menuItems (): MenuItem[] {
    return [
      {
        first: { label: 'patient-name', value: 'Max müller' },
        second: { label: 'patient-id', value: '735834' }
      },
      {
        first: { label: 'patient-name', value: 'Margaret M. Mitchell' },
        second: { label: 'patient-id', value: '735834' }
      },
      {
        first: { label: 'patient-name', value: 'Jane L. Robinson' },
        second: { label: 'patient-id', value: '735834' }
      },
      {
        first: { label: 'patient-name', value: 'Chester K. Oliver' },
        second: { label: 'patient-id', value: '735834' }
      }
    ]
  }
}

export interface MenuItem {
  first: { label: string; value: string };
  second: { label: string; value: string };
}
